import { permissions } from '@mp/kernel/permissions/domain';

enum OrganizationsPermissionsEnum {
  None = 0,
  /**
   * Manage Organizations.
   */
  ManageOrganizations = 1,
}

export const OrganizationsPermissions = permissions(OrganizationsPermissionsEnum, {
  featureId: 'Organizations',
});
