<ng-container *mpHeaderElement="'left'">
  <mp-root-module-nav label="Organisationen" />
</ng-container>

<mp-overview moduleTitle="Organisationen" [resultCount]="organizations().length" (search)="fetchOrganizations($event)">
  <button mat-menu-item routerLink="create">Neu</button>

  @if (organizationsLoaded()) {
    <cdk-virtual-scroll-viewport itemSize="100">
      <mp-overview-item
        *cdkVirtualFor="let organization of organizations(); trackBy: trackByFn"
        [path]="organization.organizationId"
      >
        <h2>{{ organization.name }}</h2>
        <span>Institutionskennzeichen: {{ organization.institutionCode ?? '-' }}</span>
      </mp-overview-item>
    </cdk-virtual-scroll-viewport>
  } @else {
    <mp-spinner />
  }
</mp-overview>
